


























































import { Component, Vue } from "vue-property-decorator";
import Toolbar from "@/components/Toolbar.vue";
import {Filtro} from "@/Classes/FiltroClass";
import StruttureService, {StruttureResponse} from "@/services/StruttureService";
import ServiziService, {ServiziResponse} from "@/services/ServiziService";
import SportService from "@/services/SportService";

@Component({
  components: {
    Toolbar,
  },
})
export default class Services extends Vue {

  public datiStruttura: StruttureResponse = []
  public listaSport: any = []
  public filtro = new Filtro();
  public sport: any = null

  public services: ServiziResponse = []

  addService() {
    this.$router.push("/addServizi");
  }

  public async RiceviStrutture(){
    try{
      const response = await StruttureService.getStrutture();

      if(response.error) {
        this.$message.error(response.msg)
      } else{
        this.datiStruttura = response.data;
        this.filtro.id_struttura = this.datiStruttura[0].struttura.value
        await this.RiceviServizi();
      }
    }
    catch (err:any)
    {
      this.$router.push("/error")
    }
  }

  public async RiceviServizi(){
    try{
      const response = await ServiziService.ListaServizi(this.filtro.id_struttura, this.sport);

      if(response.error){
        this.$message.error(response.msg)
      } else {
        this.services = response.data
        for(let i = 0; i<this.services.length; i++){
          this.services[i].prezzo = this.services[i].prezzo/100 + " €"
        }
      }
    }
    catch (err:any){

    }
  }

  public async EliminaServizio(index: number){
    try {
      const response = await ServiziService.EliminaServizio(this.services[index].id, this.filtro.id_struttura)

      if (response !== ""){
        this.$message.error(response.msg)
      } else {
        window.location.reload()
      }
    }
    catch (err:any){

    }
  }

  public async AzzeraSport(){
    this.sport = null;
    this.RiceviServizi();
  }

  async mounted(){
    this.RiceviStrutture();
    const response = await SportService.ListaSportStruttura(this.filtro.id_struttura)
    this.listaSport = response.data.map(x => {
      return {
        nome: x.nome,
        id: x.id,
      }
    })
  }
}
